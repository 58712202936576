<template>
  <div>
    <Card>
      <template #header>Hatırlatmalar</template>
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <Input placeholder="Ara.." v-model="search" class="mr-2" />
        </div>
        <div class="d-flex align-items-center">
          <b-form-group>
            <b-button variant="primary" @click="addReminder = true"
              >Yeni</b-button
            ></b-form-group
          >
        </div>
      </div>
    </Card>

    <List
      :header="header"
      :data="items"
      :pagination="pagination"
      :loading="loading"
      @updatePage="updatePage"
      moduleName="Hatırlatmalar Listesi"
      :moduleCreateEvent="true"
      :moduleSearch="search"
    >
      <template v-slot:item.action="{ item }">
        <div class="d-flex justify-content-end">
          <button class="btn p-0">
            <i class="flaticon-eye text-dark text-md"></i>
          </button>
          <button class="btn p-0" @click="openDeleteHandler">
            <i class="flaticon2-trash text-dark text-md"></i>
          </button>
          <button class="btn p-0">
            <i class="flaticon-download text-dark text-md"></i>
          </button>
        </div>
      </template>
    </List>
    <b-modal v-model="addReminder" id="note-modal">
      <template #modal-header>
        <h4><i class="flaticon2-plus mr-4"></i> Yeni Hatırlatma</h4>
      </template>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="">Hatırlatma Adı </label>
            <textarea
              name=""
              id=""
              rows="1"
              class="form-control"
              v-model="reminder_name"
            ></textarea>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Ödendiği Tarih</label>
            <input type="date" class="form-control" v-model="date" />
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="">Açıklama </label>
            <textarea
              name=""
              id=""
              rows="4"
              class="form-control"
              v-model="explanation"
            ></textarea>
          </div>
        </div>
      </div>

      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="addReminder = false">
            Vazgeç
          </b-button>
          <b-button variant="success" @click="save" class="ml-4">
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
    <DeleteOperation modalid="hatirlatmalar" />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "systemUsers",
  data() {
    return {
      addReminder: false,
      search: "",
      filters: "",
      showInsertUpdate: false,
      showDetail: false,
      showImportModal: false,
      resetForm: false,
      confirmCreate: false,
      loading: false,
      header: [
        { text: "Hatırlatma Adı", value: "reminder_name" },
        { text: "Tarih", value: "date" },
        { text: "İşlemler", value: "action" },
      ],
      items: [],
      pagination: {},
      meta: {},
      listSelectedItemIds: [],
      selectedItem: "",
      deleteOperationUrl: "",
      deleteIds: "",

      reminder_name: "",
      date: "",
      explanation: "",
    };
  },

  created() {
    this.getList();
  },
  methods: {
    save() {
      let data = {
        reminder_name: this.reminder_name,
        date: moment(this.date).format("DD/MM/YYYY"),
        explanation: this.explanation,
        user_token: this.myUser,
        property_id: this.$route.params.id
      };
      this.$ApiService
      .post("property/reminding",data)
      .then((res)=> {
        this.$generateNotification(this,"success","Hatırlatma Eklenmiştir.");
        this.addReminder = false
      })
      .catch((err) => {
        console.log(err)
        this.$generateNotification(this,"error","Hatırlatma Eklenemedi.");
      })
    },
    getList() {
      this.items = [];
      this.totalItems = 0;

      let queryLimit = this.perPage ? this.perPage : 10;
      let query = `property/reminding?Page=${
        this.page ? this.page : 1
      }&limit=${queryLimit}&user_token=${this.myUser}&property_id=${
        this.$route.params.id
      }`;
      if (this.search) {
        query += `&search=${this.search}`;
      }

      this.$dbFunctions
        .get(query)
        .then((res) => {
          this.items = res.response.data;
          this.totalItems = res.response.count;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updatePage(value) {
      this.meta = value;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openDeleteHandler(key) {
      this.$bvModal.show("modal-deleteOperationhatirlatmalar");
      let index = this.notes.indexOf(key);
      this.notes.splice(index, 1);
    },
  },
  watch: {
    meta: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    search: function (params) {
      this.getList();
    },
  },
  computed: {
    ...mapGetters(["myUser"]),
  },
};
</script>
